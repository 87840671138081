<template>
    <div class="page page-normal">
        <lego-nav-bar @link-event="linkEvent" :logo-text="'AloCart'" drop-down-animation="bounce" :items="menu" has-separator @logo-clicked="redirect"/>
        <div class="page-content container-fluid">
            <transition enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <div class="bgc-primary-900 p-3">
            © {{ year }} Powered by Xeoscript Technologies
        </div>
    </div>
</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import { mapActions } from 'vuex';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name       : 'MainLayout',
    components : {
        LegoNavBar
    },
    data () {
        return {
            menu : getMenu(),
            year : new Date().getFullYear()
        };
    },
    metaInfo : {
        titleTemplate : '%s - Alocart Micro Shop Vue',
        htmlAttrs     : {
            lang : 'en',
            amp  : true
        }
    },
    methods : {
        ...mapActions(['signOut']),
        redirect () {
            this.$router.push({ path : '/' });
        },

        linkEvent (name) {
            if (name === 'logout') {
                localStorage.clear();
                this.signOut();
                axios.get(urls.auth.logout);
                this.$router.push('/login/');
            }
        }
    }

};
</script>
