import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text : 'Dashboard',
            link : '/'
        },
        {
            text : 'Catalog',
            link : '/catalog/'
        },
        {
            text : 'Orders',
            link : '/orders/'
        },
        {
            text : 'Customers',
            link : '/customers/'
        },

        // this section is comes on the right side //
        {
            type : 'separator'
        },

        {
            icon              : 'fa fa-user-circle-o',
            text              : 'John Snow',
            dropdownPlacement : 'right',
            link              : '#',
            items             : [
                {
                    text : 'Profile',
                    link : '/profile/'
                },
                {
                    text  : 'Change Password',
                    event : '#'
                },
                {
                    text  : 'Logout',
                    event : 'logout'
                }
            ]
        }
    ];

    if (user.isSuperAdmin) {
        menu.push(

        );
    }

    const adminMenu = [

    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
